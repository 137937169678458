<template>
  <TransitionRoot as="template" :show="openProductModal">
    <Dialog as="div" class="relative z-300" @close="openProductModal = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black/50 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative inline-block transform overflow-hidden bg-white shadow-xl transition-all sm:w-full sm:max-w-lg sm:align-middle"
            >
              <div>
                <div v-if="sortedImages !== undefined">
                  <Swiper
                    :modules="[Navigation]"
                    :navigation="true"
                    :style="{
                      '--swiper-navigation-color': '#fff',
                      '--swiper-pagination-color': '#fff',
                    }"
                    class="whiteNavigator -my-2"
                  >
                    <SwiperSlide
                      class="relative max-h-[986px]"
                      v-for="(image, id) in sortedImages"
                      :key="id"
                    >
                      <InnerImageZoom
                        :src="baseUrl+image.medium"
                        :zoomSrc="baseUrl+image.original"
                        zoomType="hover"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";

const storeConfig = useStoreConfig()
const {baseConfig} = storeToRefs(storeConfig)


const sortedImages = useState("sortedImages", () => [])
const openProductModal = useState("openProductModal", () => false);
const baseUrl = computed(() => {
  if (baseConfig.value.cdn_provider === 'bunny') {
    return 'https://labsoffice.b-cdn.net'
  } else {
    return ''
  }
})
</script>
<style>
.vh--message {
  @apply hidden;
}
</style>
